// 上传文件
import { getToken } from "@/utils/auth";
import { Message } from "element-ui";
import { getCurrentInstance, computed } from "vue";
export default function useUploadFile() {
  const { proxy } = getCurrentInstance();
  // 上传前
  const beforeImgUpload = (rawFile) => {
    if (rawFile.type !== "image/jpeg" && rawFile.type != "image/png") {
      Message.error(proxy.$t("market.uploadImg"));
      return false;
    } else if (rawFile.size / 1024 / 1024 > 5) {
      Message.error(proxy.$t("market.uploadImgSize"));

      return false;
    }
    return true;
  };
  // 上传成功
  const handleAvatarSuccess = (response, uploadFile) => {
    imageUrl.value = URL.createObjectURL(uploadFile.raw);
  };
  // 上传地址
  const action = computed(() => {
    return process.env.VUE_APP_BASE_API + "/api/upload";
  });

  // header
  const header = computed(() => {
    return {
      authorization: getToken(),
    };
  });
  return {
    action,
    beforeImgUpload,
    handleAvatarSuccess,
    header,
  };
}
